import SubscriptionsList from "src/components/subscriptions-list/SubscriptionsList"
import { SanityCallToAction, SanityRichText, Subscription } from "src/sanity/types"
import { Heading, Section } from "src/utils"
import cn from "classnames"
import s from "./SubscriptionsListModule.module.scss"
import CallToAction from "src/components/call-to-action/CallToAction"
import { Container } from "src/layout/container/Container"
import RichText from "src/components/rich-text/RichText"
import { useState } from "react"
import { getTranslation } from "src/lib/translations"
import { useSettings } from "src/layout/page/settingsContext"
import RibbonModule from "../ribbon-module/RibbonModule"

interface SubscriptionsListModuleProps {
    subscriptions: Omit<Subscription, "benefits">[]
    title: SanityRichText
    subtitle?: string
    disclaimer?: SanityRichText
    cta?: SanityCallToAction
}

export default function SubscriptionsListModule({ title, subtitle, cta, subscriptions, disclaimer }: SubscriptionsListModuleProps) {
    const { lang, segment } = useSettings()
    const hasYearlyPrice = subscriptions.some(subscription => subscription.priceYearly)
    const [showPriceYearly, setShowPriceYearly] = useState(hasYearlyPrice)
    const t = getTranslation("subscriptionList", lang)

    const isSaleActive = false
    let ribbonSaleText: Sanity.Schema.RichText


    // if (segment === "private") {
    //     if (privateSaleActive && lang === "dk") {
    //         isSaleActive = true

    //         disclaimer = <>*Rabatkoden ‘Dec23Free’ giver dig Lunar Plus gratis i 3 måneder. Du skal bruge koden senest 31. december 2023. Når din prøveperiode slutter, fortsætter du med Lunar Plus for 69 kr. pr. måned. Du kan opsige Lunar Plus når som helst. Hvis du ikke vil betale normalpris for Lunar Plus, skal du opsige Plus senest den dag, din prøveperiode udløber. Når du opsiger, kan du vælge hvilken som helst anden Lunar-pakke. Hvis du nedgraderer, vil nogle produkter bortfalde og/eller koste noget separat. Rabatten gælder for nye Lunar-brugere.</>

    //         ribbonSaleText = [
    //             {
    //                 "_key": "509b3df9b4d9",
    //                 "children": [
    //                     {
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": "Kun i december! ",
    //                         "_key": "e8780a2ec19f0",
    //                     },
    //                     {
    //                         "_type": "span",
    //                         "marks": [],
    //                         "_key": "ad676cacdca1",
    //                         "text": "Få 3 måneders gratis Lunar Plus.\n",
    //                     },
    //                     {
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "_key": "bb20047ee748",
    //                         "text": "Brug rabatkoden ‘",
    //                     },
    //                     {
    //                         "_type": "span",
    //                         "marks": [],
    //                         "_key": "119263a5b3d4",
    //                         "text": "Dec23Free",
    //                     },
    //                     {
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "_key": "b70dc8f857dc",
    //                         "text": "’, når du opretter dig.",
    //                     },
    //                 ],
    //                 "markDefs": [],
    //                 "_type": "block",
    //                 "style": "normal",
    //             },
    //         ]
    //     }
    // }
    // if (segment === "business") {
    //     isSaleActive = ["dk", "en"].includes(lang)
    //     if (lang === "dk") {
    //         disclaimer = <><b style={{ fontWeight: "500" }}>*Betingelser og vilkår for ‘Spar 25% hele det første år’.</b><br />
    //         For at få rabatten skal du have en dansk registreret virksomhed og bo i Danmark. Du kan bruge kampagnekoden ‘Lunar25biz’ t.o.m. 31. december 2023; den giver 25% rabat på Lunar Business de første 12 måneder. Rabatten gælder for alle pakker, og både ved månedlig og årlig betaling. Du kan bruge rabatkoden én gang pr. virksomhed. Tilbuddet gælder kun for nye Lunar Business-kunder. Tilbuddet kan ikke kombineres med andre kampagner.</>

    //         ribbonSaleText = [
    //             {
    //                 "_key": "1c32ecaac634",
    //                 "_type": "block",
    //                 "children": [
    //                     {
    //                         "_key": "695cef1205630",
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": "Lunar Business fylder 3 år! ",
    //                     },
    //                     {
    //                         "_key": "11adf596505d",
    //                         "_type": "span",
    //                         "marks": [],
    //                         "text": "Spar 25% hele det første år.*",
    //                     },
    //                     {
    //                         "_key": "7a61b447ac58",
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": "\nBrug rabatkoden ",
    //                     },
    //                     {
    //                         "_key": "51108e77b1e2",
    //                         "_type": "span",
    //                         "marks": [],
    //                         "text": "‘Lunar25biz’",
    //                     },
    //                     {
    //                         "_key": "73928a05edae",
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": ", når du ansøger i december.",
    //                     },
    //                 ],
    //                 "markDefs": [],
    //                 "style": "normal",
    //             },
    //         ]
    //     }
    //     if (lang === "en") {
    //         disclaimer = <><b style={{ fontWeight: "500" }}>*Terms and conditions for ‘Save 25% the whole first year‘.</b><br />
    //         To take part in the offer your business must be registered in Denmark, and you need to live in Denmark yourself. You can use the promo code ‘Lunar25biz’ until December 31, 2023 – at the latest. The code provides a 25% discount on Lunar Business for the first 12 months. The discount is valid for all plans – no matter if you pay on a monthly or annual basis. You can use the promo code once per business. The offer is only valid for new Lunar Business customers. The offer can’t be combined with other promotions.</>

    //         ribbonSaleText = [
    //             {
    //                 "_key": "1cb4f4f7ad63",
    //                 "_type": "block",
    //                 "children": [
    //                     {
    //                         "_key": "9f3d76003f4e0",
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": "Lunar Business celebrates 3 years! ",
    //                     },
    //                     {
    //                         "_key": "246fc04c8cff",
    //                         "_type": "span",
    //                         "marks": [],
    //                         "text": "Save 25% the whole first year.*\n",
    //                     },
    //                     {
    //                         "_key": "a98907f9d653",
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": "Use the code ",
    //                     },
    //                     {
    //                         "_key": "6a2f19bccb3a",
    //                         "_type": "span",
    //                         "marks": [],
    //                         "text": "‘Lunar25biz’",
    //                     },
    //                     {
    //                         "_key": "5606b8dae71b",
    //                         "_type": "span",
    //                         "marks": [
    //                             "light",
    //                         ],
    //                         "text": " when applying in December.",
    //                     },
    //                 ],
    //                 "markDefs": [],
    //                 "style": "normal",
    //             },
    //         ]
    //     }
    // }

    return (
        <Section>
            <div className={s["subscriptions-list-module"]} id="overview">
                <Container>
                    <div className={cn("paragraph-100", s["subscriptions-list-module__header"], hasYearlyPrice ? s["subscriptions-list-module__header--has-yearly"] : null )}>
                        <>
                            {(title || subtitle) && (
                                <div>
                                    {title && (
                                        <Heading className={cn("heading-300", s["subscriptions-list-module__title"])}><RichText blocks={title} /></Heading>
                                    )}
                                    {subtitle && (
                                        <p className={cn("paragraph-300", s["subscriptions-list-module__subtitle"])}>
                                            {subtitle}
                                        </p>
                                    )}
                                </div>
                            )}

                            {hasYearlyPrice && (
                                <div className={cn(s["subscriptions-list-module__yearly-toggler-wrapper"])}>
                                    <div className={cn(s["subscriptions-list-module__yearly-toggler"])} onClick={() => setShowPriceYearly((prevState) => !prevState)}>
                                        <span className={cn(showPriceYearly ? null : "active")}>{t.payMonthly}</span>
                                        <span className={cn(showPriceYearly ? "active" : null)}>{t.payYearly}</span>
                                    </div>
                                    <div className={cn("paragraph-100 text-center", s["subscriptions-list-module__yearly-disclaimer"])}>{t.yearlyPaymentDisclaimer}</div>
                                </div>
                            )}
                        </>
                    </div>
                    {cta && cta.style && (
                        <div className={s["subscriptions-list-module__cta"]}>
                            <CallToAction {...cta} />
                        </div>
                    )}
                    {isSaleActive && (
                        <RibbonModule ribbonType="ribbon" list={[]} staticRibbonText={ribbonSaleText} backgroundColor={segment === "private" ? "primary-blue" : "primary-orange"} textColor={"white"} />
                    )}
                    <div style={{ height: "20px" }}></div>
                    <SubscriptionsList subscriptions={subscriptions} showPriceYearly={showPriceYearly} />
                    {disclaimer && (
                        <RichText blocks={disclaimer} className={s["subscriptions-list-module__disclaimer"]} />
                    )}

                </Container>
            </div>
        </Section>
    )
}
